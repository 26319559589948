@charset "UTF-8";

.p-1px {
  padding: 1px !important;
}

.p-2px {
  padding: 2px !important;
}

.m-auto {
  margin: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-0 {
  margin: 0rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.my-0 {
  margin: 0rem 0 !important;
}

.mx-0 {
  margin: 0 0rem !important;
}

.p-0 {
  padding: 0rem !important;
}

.py-0 {
  padding: 0rem 0 !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mr-half {
  margin-right: 0.5rem;
}

.m-1 {
  margin: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.my-1 {
  margin: 1rem 0 !important;
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.py-1 {
  padding: 1rem 0 !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.m-2 {
  margin: 2rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.my-2 {
  margin: 2rem 0 !important;
}

.mx-2 {
  margin: 0 2rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.py-2 {
  padding: 2rem 0 !important;
}

.py-2 {
  padding-top: 2rem !important;
}

.px-2 {
  padding: 0 2rem !important;
}

.m-3 {
  margin: 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.my-3 {
  margin: 3rem 0 !important;
}

.mx-3 {
  margin: 0 3rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.py-3 {
  padding: 3rem 0 !important;
}

.py-3 {
  padding-top: 3rem !important;
}

.px-3 {
  padding: 0 3rem !important;
}

.m-4 {
  margin: 4rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.ml-4 {
  margin-left: 4rem !important;
}

.my-4 {
  margin: 4rem 0 !important;
}

.mx-4 {
  margin: 0 4rem !important;
}

.p-4 {
  padding: 4rem !important;
}

.py-4 {
  padding: 4rem 0 !important;
}

.py-4 {
  padding-top: 4rem !important;
}

.px-4 {
  padding: 0 4rem !important;
}

.m-5 {
  margin: 5rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.mr-5 {
  margin-right: 5rem !important;
}

.ml-5 {
  margin-left: 5rem !important;
}

.my-5 {
  margin: 5rem 0 !important;
}

.mx-5 {
  margin: 0 5rem !important;
}

.p-5 {
  padding: 5rem !important;
}

.py-5 {
  padding: 5rem 0 !important;
}

.py-5 {
  padding-top: 5rem !important;
}

.px-5 {
  padding: 0 5rem !important;
}

.py-half {
  padding: 0.5rem 0 !important;
}

/* ------------------ Color ------------------ */
.bg-pink {
  background-color: #fe8291 !important;
  /* background-color:rgba(0, 0, 0, 0.767) */
}

.bg-white {
  background-color: white !important;
}
.text-gold {
  color: goldenrod;
}

.text-blue {
  color: rgb(36, 149, 241);
}
.text-dark {
  color: rgba(0, 0, 0, 0.4) !important;
}

.text-danger {
  color: rgb(224, 67, 67) !important;
}

.text-white {
  color: white !important;
}

.text-pink {
  color: #fe8291 !important;
}

.text-info {
  color: goldenrod !important;
}

.text-gray {
  color: gray !important;
}

.text-success {
  color: #28a745;
}

.text-overallRating {
  color: #fe8291;
  background-color: white;
  padding: 2px 6px;
}

.text-decoration-none {
  text-decoration: none;
}

.text-underlined {
  text-decoration: underline;
}

.border-pink {
  border: 1px solid #fe8291 !important;
}

.background-pink {
  background-color: #fe8291 !important;
}

/* ------------------ Font ------------------- */
.text-bold {
  font-weight: bold;
}
.text-small {
  font-size: 0.75rem !important;
}

.text-1rem {
  font-size: 1rem;
}

.text-extraLarge {
  font-size: 4rem;
}

.text-align-right {
  text-align: right !important;
}
.text-align-left {
  text-align: left !important;
}
.text-align-center {
  text-align: center;
}

.text-crossed {
  text-decoration: line-through !important;
}

@media screen and (max-width: 699px) {
  .text-small {
    font-size: 0.6rem !important;
  }
}

/* ------------------- Display ------------------ */

.display-none {
  display: none !important;
}

.display-flex {
  display: flex !important;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

/* ---------------------- Size --------------------------- */

.width-100 {
  width: 100px !important;
}

/* ------------------ Main Components ------------------ */

html,
body {
  margin: 0;
  padding: 0;
  background-color: black;
  color: white;
}

html {
  height: 100%;
}

#root {
  position: relative;
  height: 100%;
  /* min-height: calc(100vh - 40px); */
}

section {
  box-shadow: 0 5px 5px rgba(63, 63, 63, 0.493);
  padding: 8px;
  margin-bottom: 2rem;
}

.container {
  max-width: 1200px;
  margin: 9rem auto 3rem;
  /* padding: 0.5rem 1rem; */
  z-index: initial;
  min-height: calc(100vh - 96px - 48px - 64px);
}

a {
  cursor: pointer;
}

input[type='text'] {
  color: black;
}

.divider-pink {
  border-bottom: 4px solid #fe8291;
  margin-right: 2rem;
  margin-left: 2rem;
}

.info-box,
.confirmation-box {
  /* display: block; */
  width: 400px;
  /* max-width: 95vw; */
  margin: 0 auto;
}

.info-box-title,
.confirmation-box h1 {
  position: relative;
  text-align: center;
  background-color: #fe8291;
}

.info-box-title h2 {
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
}

.info-box-content {
  border: 1px solid white;
  border-top: none;
}

.info-box-content,
.confirmation-box p {
  background-color: black;
  padding: 16px;
}

.confirmation-box h1 {
  margin: 0 auto;
  padding-top: 8px;
  padding-bottom: 8px;
}

.confirmation-box p {
  margin-top: 0;
}

.btns {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  width: 100%;
}

.btns button {
  padding: 8px 2rem;
}

.btns button:hover {
  background-color: #fff;
  color: #fe8291;
  font-size: 20px;
  padding: 6px 28px;
}

.btns :first-child {
  margin-left: auto;
}

.btns :last-child {
  margin-right: auto;
}

.info-box-content-rules {
  margin-left: 100px;
}

.big-square-btn {
  /* width: %; */
  margin: 8px;
}

/* .search-form {
  display: flex;
  margin-left: auto;
}

.search-form input {
  margin-left: auto;
  margin-right: 1rem;
}

.search-form button {
  margin-left: 1rem;
  margin-right: auto;
} */

.grid-container {
  display: grid;
  grid-template-columns: 80% 20%;
}

.grid-container-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-container-user {
  display: grid;
  grid-template-columns: 20% 80%;
  min-height: 90%;
  padding: 32px 0;
}

.data-container-grid {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.buy-vip {
  /* width: fit-content; */
  padding-top: 50px;
  margin: auto;
}

.buy-vip-item,
.total-data-box {
  width: 300px;
  margin: auto;
  text-align: center;
  border: 1px solid #fe8291;
}

.buy-vip-content {
  margin-bottom: 2rem;
}

.buy-vip-title h2,
.total-data-box h2 {
  margin: 0 auto;
  padding: 1.5rem;
}

.buy-vip-title,
.total-data-box-title {
  background-color: #fe8291;
}

.buy-vip-item {
  margin: 4rem;
}

.buy-vip-item button {
  width: 100%;
  font-size: 1.5rem;
}

.buy-vip-item button:hover {
  background-color: white;
  color: #fe8291;
}

.payment-box {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
  /* border: 1px solid white; */
}

.payment-box input[type='text'] {
  margin-right: auto;
  padding: 8px 4px;
  margin-top: 1rem;
}

.payment-box button {
  /* margin-top: 1rem; */
  padding: 8px 16px;
  font-weight: bold;
  font-size: 1.25rem;
  margin: 1rem auto 0 auto;
}

.payment-box-content {
  display: flex;
  width: 300px;
}

.payment-box-title {
  width: 100%;
  text-align: center;
  background-color: #fe8291;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* height: 64px; */
  margin: 0;
}

.payment-qrcode {
  width: 100%;
  /* margin: 0 auto; */
}

/* .total-data-box-content {
} */
textarea {
  width: 90%;
  padding: 8px;
}

.payment-page-instructions {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: 2rem auto;
  padding: 0 2rem;
  border: 1px solid white;
}

.payment-page-instructions img {
  max-width: 400px;
  padding: 1rem;
}

@media screen and (max-width: 1099px) {
  .container {
    padding: 0.5rem 0;
    margin-top: 8rem;
    width: calc(100% -16px);
  }
  .inner-container {
    padding: 0 0.5rem;
  }
  .buy-vip {
    padding-top: 0;
    /* display: block !important; */
  }
  .buy-vip-item {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 699px) {
  .container {
    margin-top: 4rem;
    margin-bottom: 3rem;
    width: 100vw;
  }

  .buy-vip {
    margin: 0;
    padding: 0;
    width: 100vw;
    /* width: 100%; */
  }

  .buy-vip-item {
    margin: 4px;
    width: calc(50vw - 8px);
    /* width: 100%; */
  }

  .buy-vip-item button {
    font-size: 2vw;
  }

  .buy-vip-title h2 {
    padding-left: 0;
    padding-right: 0;
  }

  .buy-vip-title h2,
  .buy-vip-content,
  .buy-vip-content h2 {
    font-size: 3vw;
  }

  .buy-vip-content .text-extraLarge {
    font-size: 4vw;
  }

  .buy-vip-content h1 {
    font-size: 3vw;
  }

  .payment-box {
    margin-top: 1rem;
    width: 60vw;
  }

  .payment-box button {
    font-size: 0.8rem;
    padding: 4px 8px;
  }
  .payment-page-instructions {
    margin: 1rem 4px;
    width: 90vw;
    padding: 4px;
    font-size: 0.75rem;
  }

  .payment-page-instructions img {
    width: 60%;
  }

  .info-box-title h2 {
    font-size: 3vw;
  }

  .info-box-content h3 {
    font-size: 3vw;
  }

  .info-box-content label {
    font-size: 12px !important;
    padding: 4px 8px !important;
  }
}

/* 3:1分割 */

/* -------------------- Small Components --------------------- */
.btn {
  cursor: pointer;
}

.btn-link {
  background-color: transparent;
  border: none;
  margin: 4px 4px 4px 0;
}

.already-favorated-btn {
  padding: 4px 24px !important;
  margin: 8px 16px 4px 4px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-close {
  color: #fe8291;
  background: none;
  border: none;
  margin: 1rem 1rem 0 0;
  padding: 0.25rem 0.5rem;
  /* float: right; */
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.5rem;
  font-weight: bold;
  width: fit-content;
}

.btn-close-white {
  color: white;
  background: none;
  border: none;
  margin: 4px 4px 0 0 !important ;
  padding: 0.25rem 0.5rem !important;
  /* float: right; */
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  font-weight: bold;
  width: fit-content;
}

.link-btn-pink,
.btn-pink {
  border: none;
  border-radius: 5px;
  background-color: #fe8291;
  color: white;
  padding: 8px 0;
  font-size: 1rem;
}

.btn-green {
  background-color: rgb(23, 192, 23);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  /* font-weight: bold; */
}

.btn-text-pink {
  text-decoration: none;
  border: none;
  background-color: transparent;
  color: #fe8291;
  font-size: 1.5rem;
}

.btn-text-pink:hover {
  border-bottom: 2px solid #fe8291;
  color: white;
}

.link-btn-pink {
  padding: 2px 1rem 4px 1rem;
}

.link-btn-pink:hover {
  background-color: white;
  color: #fe8291;
}

.middle-btn,
.left-most-btn {
  margin: 1rem;
}
.right-most-btn {
  margin: 1rem auto 1rem 1rem;
}

.btn-success {
  border: none;
  border-radius: 5px;
  background-color: #28a745;
  color: white;
  padding: 8px 0;
  font-size: 1rem;
}

.btn-submit {
  border: none;
  border-radius: 5px;
  background-color: #fe8291;
  color: white;
  font-size: 1rem;
  padding: 4px 8px 6px;
}

.btn-white {
  font-weight: bold;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  padding: 8px 0;
}

.btn-gold {
  background-color: gold !important;
  color: rgb(94, 80, 0) !important;
  border: 1px solid rgb(94, 80, 0) !important;
  border-radius: 5px;
  padding: 4px 24px;
  font-size: 16px;
  font-weight: bold;
}

.btn-square {
  border-radius: 0 !important;
}

.range-slider {
  /* appearance: none; */
  background-color: #fe8291;
}

.slide-limit-label {
  margin: auto 16px;
}

.tab {
  margin: 1rem auto 0;
  width: 100%;
  display: flex;
  font-size: 1rem;
  border-bottom: 2px solid #fe8291;
}

.tablinks {
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  padding: 4px 24px 8px;
  border: none;
}

.tablinks.selected {
  color: #fe8291;
  background-color: #272727;
  /* border-bottom: 2px solid #fe8291; */
  /* box-shadow: 0 2px #fe8291; */
}

.tabs {
  width: 100%;
  margin: 0;
}

.tabcontent {
  color: white;
  margin: 1rem 0 0 0;
  padding: 0 2rem;
}

.new-user-box {
  height: fit-content !important;
  margin-top: 1rem !important;
  top: 0 !important;
}

.tablinks-divider {
  /* height: 75%; */
  margin: 8px 0;
  width: 2px;
  background-color: #fe8291;
}

.tab.user-tab {
  display: block !important;
  margin: 0;
  border-bottom: none;
  border-right: 2px solid #fe8291;
  z-index: 10;
}

.tablinks.user-tablinks {
  display: block;
  margin: 8px auto;
  padding: 1rem 2rem;
  color: goldenrod;
}

.tabcontent-user-item {
  display: flex;
}

.input-group-item label {
  font-size: 20px;
  width: 120px;
  height: fit-content;
  margin-bottom: 8px;
  margin-right: 8px;
}

.tabcontent-user-input-group {
  display: flex;
}

.tabcontent-user-input-group input[type='text'],
.tabcontent-user-input-group input[type='password'] {
  margin: 8px;
  width: 200px;
  padding: 4px;
}

.tabcontent-title {
  width: 150px;
  display: flex;
  margin: 8px 32px 8px 0;
}

.tabcontent-text,
.user-tabs-update-btn {
  display: flex;
  margin: 8px;
  font-size: 20px;
}
.change-password-btn {
  margin-left: auto;
  margin-right: 0;
}

.tabcontent-text {
  height: 100%;
}

.user-tabs-update-btn {
  background-color: #fe8291;
  color: white;
  border-radius: 3px;
  padding: 2px 1.5rem;
  text-align: center;
}

.input-group {
  display: grid;
  grid-template-columns: 120px auto;
  /* width: calc(100%-150px); */
  margin: 16px 8px;
}
.input-group label {
  width: 100%;
  display: block;
  font-size: 1.25rem;
}

.input-group input {
  width: 100%;
  height: 32px;
}

.input-group-item {
  margin-right: auto;
}

.please-buy-vip,
.confirmation-box-container {
  position: fixed;
  top: 30vh;
  width: 100%;
  margin: auto;
  padding-top: 30vh;
  z-index: 15;

  height: 100%;
  /* width: 100%; */
  /* position: absolute; */
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  /* z-index: 2; */
}

/* @media screen and (max-width: 699px){
  .input-group{
    width: auto !important
  }
} */

@media screen and (max-width: 1099px) {
  .tab.user-tab {
    position: fixed;
  }
  .tabs.user-tabs {
    margin-left: 50px;
  }
  .tabcontent {
    padding: 0 8px;
    margin: 0;
  }
  .tabcontent-title {
    display: block;
    margin: 8px;
    font-size: 1.25rem !important;
    width: auto;
    border-bottom: 1px solid rgb(95, 95, 95);
    padding-bottom: 4px;
  }
  .tabcontent-user-item {
    display: block;
  }
  .tabcontent-user-input-group {
    display: flex;
  }
  /* .tabcontent-title{
    display: block;
    width: 100%;
  } */
  .tabcontent-user-input-group input {
    /* display: block; */
    width: calc(100% - 136px) !important;
  }

  /* .tabcontent,
  .tabcontent-title,
  .tabcontent input{
    display: block;
    width: 90%;
  } */
  /* .user-tabs-update-btn {
    margin-left: auto;
  } */

  .input-group input {
    width: calc(100% - 8px) !important;
    /* width: 100%; */
  }
  .change-password-btn {
    margin-right: 8px;
  }
  .info-box {
    max-width: 95vw;
  }

  .btn-text-pink {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 699px) {
  .btn-text-pink {
    font-size: 1rem;
  }
  .left-most-btn {
    margin: 8px 4px 8px 8px;
  }

  .middle-btn {
    margin: 8px 4px;
  }
  .right-most-btn {
    margin: 8px auto 8px 4px;
  }
}

.transactions,
.favoriteDancers {
  /* width: 100%; */

  padding: 16px 0;
}

.favoriteDancers {
  margin: 0 4px 4px;
  /* width: 100%-16px; */
  /* padding: 8px; */
}

.favoriteDancers .dancer-item {
  padding: 4px;
  margin-bottom: 1rem;
  background-color: #323232;
}
.favoriteDancers .grid-four {
  grid-template-columns: 35% 20% 25% 20%;
}

.favoriteDancers .dancer-item-first-line {
  font-size: 16px;
}

.transaction-item {
  margin: 4px 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgb(160, 160, 160);
}

.transaction-item-detail {
  display: inline-block;
  margin-left: 0;
  margin-right: auto;
  margin: 8px 0;
}

.transaction-item-btns {
  display: inline-block;
  margin-left: auto;
  margin-right: 0;
}

.favoriteDancers {
  display: grid;
  grid-template-columns: 50% 50%;
}

.sharing button {
  margin: 8px auto;
  padding: 2px 24px 4px 24px;
  align-self: center;
  display: flex;
}

.sharing p {
  display: flex;
}

.sharing h3,
.sharing p {
  margin-top: 4px;
  margin-bottom: 8px;
}

.desc-text {
  color: gray;
  /* margin-top: auto !important; */
}

@media screen and (min-width: 499px) {
  .withdraw-btn {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}

@media screen and (max-width: 699px) {
  .favoriteDancers .dancer-item-first-line {
    font-size: 14px;
  }
  .dancer-item {
    width: 100%;
  }
}

@media screen and (max-width: 1099px) {
  .grid-container-user {
    display: flex;
    padding: 16px 0;
  }
  .tab.user-tab {
    width: 48px;
  }
  .tablinks.user-tablinks {
    margin: 0 auto 0;
    font-size: 1rem;
    padding: 8px 16px;
  }

  .hide-on-small {
    display: none;
  }

  .favoriteDancers {
    display: flex;
    flex-wrap: wrap;
  }
}
/* ------------------ New Dancer ------------------ */
.search-field-new-dancer {
  grid-template-columns: 180px auto !important;
}

.search-field-new-dancer .form-label {
  width: auto;
  display: block !important;
  margin-left: auto;
  margin-right: 0;
  padding-right: 2rem;
  text-align: right;
}

.search-field-new-dancer .form-input span {
  margin-left: 8px;
}

.new-dancer-form select {
  font-size: 1rem;
  padding: 4px 8px;
  width: 150px;
}

.new-dancer-form input[type='number'],
.new-dancer-form input[type='text'] {
  font-size: 1rem;
  padding: 4px;
  width: 142px;
}
.search-field-new-dancer .photos-input {
  width: 100%;
}

.search-field-new-dancer .photos-preview-groups {
  width: 100%;
}
/* .search-field-new-dancer .photos-preview-group {
  max-width: 100%;
  display: flex;
} */

/* .search-field-new-dancer .dancer-photos-preview { */
.search-field-new-dancer .photos-preview-group {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.search-field-new-dancer .photos-preview-group img {
  object-fit: contain;
  padding: 4px;
  width: calc(100% - 8px);
  height: auto;
}

.search-field-new-dancer .sub-label {
  margin-left: 4px;
  width: 120px;
}

.new-dancer-submit-btn {
  margin: 1rem auto auto;
  padding: 8px 32px;
}

.remove-img-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 4px 6px;
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #ffffff4b;
  border: none;
}

.remove-img-btn:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
}

.dancer-photos-preview-wrap {
  position: relative;
  margin: 4px;
}

.dancer-photos-preview-order {
  position: absolute;
  margin: 4px;
  padding: 1rem;
  left: 0;
  top: 0;
  z-index: 2;
  font-weight: bolder;
  background-color: #ffffff4b;
}

.dancer-photos-preview {
  /* z-index: 1; */
  /* position: absolute; */
  object-fit: contain;
  padding: 0;
  width: calc(100% - 8px);
  height: auto;
}

.dancer-photos-preview-input {
  margin: 4px;
  width: calc(100% - 8px) !important;
}

.dancer-photos-preview-name {
  margin: 4px;
}

/* ------------------ Alert ------------------ */

.alert {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: calc(100% - 16px);
  padding: 0.8rem;
  margin: 0 auto;
  opacity: 0.9;
  background: #f4f4f4;
  color: #333;
  z-index: 99;
  text-align: center;
  font-weight: 100;
  font-size: 1.5rem;
}

.alert.alert-primary {
  background-color: #17a2b8;
  color: #fff;
}

.alert.alert-light {
  background-color: #f4f4f4;
  color: #333;
}

.alert.alert-dark {
  background-color: #28a745;
  color: #fff;
}

.alert.alert-success {
  background-color: #28a745;
  color: #fff;
}

.alert.alert-danger {
  background-color: #dc3545;
  color: #fff;
}

.alert.alert-white {
  background-color: white;
  color: #333;
  border: #ccc solid 1px;
}

/* ------------------ Other Utitlies --------------------- */
.dark-overlay {
  height: 150%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.bordered-danger {
  border: 1px solid #fe8291;
}

.rounded {
  border-radius: 3px;
}

/* ------------------ Navbar ------------------ */
.navbar {
  /* display: flex; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  position: fixed;
  z-index: 20;
  width: 100vw;
  /* height: 48px; */
  top: 0;
  background-color: black;
}

.navbar-container {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
  margin: auto;
  /* z-index: 3; */
  width: 100%;
  max-width: 1200px;
}

.navbar-navlink,
.navbar-wrapper a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  margin-left: 1.5rem;
}

.navlink-icon {
  margin-left: 8px;
}

@media screen and (max-width: 699px) {
  .navbar-wrapper {
    margin: auto 8px 16px 8px;
  }
  .navbar-navlink,
  .navbar-wrapper a {
    margin-left: 8px;
    font-size: 1rem;
  }
  .navbar-navlink {
    margin-bottom: 8px;
  }

  .navlink-icon {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 599px) {
  .navbar-navlink:hover,
  .navbar-wrapper a:hover {
    color: #fe8291;
  }
  .navbar-navlink:hover {
    /* color: black; */
    text-shadow: 2px 2px black;
  }

  .tablinks.user-tablinks:hover {
    color: goldenrod;
  }

  .user-tabs-update-btn:hover {
    cursor: pointer;
    color: #fe8291;
    background-color: black;
  }

  .tablinks:hover {
    cursor: pointer;
    background-color: #272727;
    color: #fe8291;
    /* border-bottom: 2px solid #fe8291; */
    /* box-shadow: 0 2px #fe8291; */
  }

  .submit-btn:hover {
    cursor: pointer;
  }
}

.navbar-wrapper {
  display: flex;
  align-items: flex-end;
}

.site-header {
  text-decoration: none;
  /* font-size: 1.5rem; */
  font-size: 3rem !important;
  margin: 4px 8px 4px 0 !important;
  color: white;
  vertical-align: baseline;
}
.site-header-small {
  background-color: #fe8291;
  color: white;
  font-size: 2.25rem;
}

.site-logo img {
  height: 40px;
  width: auto;
  -o-object-fit: contain;
  object-fit: contain;
}

.site-header-large {
  padding: 2px 4px 0;
}

.site-header-small {
  /* display: flex; */
  padding: 2px 4px;
}

.site-header-smaller {
  font-size: 1.5rem;
  margin: auto 16px 8px;
  vertical-align: baseline;
}

/* .site-header-small:hover {
  background-color: white;
  color:#fe8291;
} */

@media screen and (max-width: 1099px) {
  .site-header-large {
    font-size: 24px !important;
  }

  .site-header-small {
    font-size: 18px !important;
  }

  .site-header-smaller {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 699px) {
  .navbar-wrapper {
    margin: auto 8px 0;
  }
}

/* -------------- Sidenav --------------- */

.side-nav {
  padding-bottom: 1rem;
  width: 180px;
  position: fixed;
  top: 64px;
  right: -180px;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-x: hidden;
  -webkit-transition: right 0.5s;
  transition: right 0.5s;
  z-index: 10;
}

.side-nav ul {
  list-style-type: none;
  padding: 24px;
}

/* .side-nav a, */
.side-nav li,
.side-nav .closebtn,
.side-nav .nav-login,
.side-nav .nav-logout {
  padding: 0.75rem 0.25rem;
  text-decoration: none;
  font-size: 1.2rem;
  color: #fff;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.side-nav a {
  text-decoration: none;
  color: #fff;
}

.side-nav li,
.side-nav .nav-login,
.side-nav .nav-logout {
  text-align: right;
}

.side-nav .closebtn {
  text-align: right;
  margin-right: 10px;
}

.side-nav a:hover,
.side-nav .closebtn:hover,
.side-nav .nav-login:hover,
.side-nav .nav-logout:hover {
  color: #fe8291;
}

.side-nav a.active,
.side-nav .closebtn.active,
.side-nav .nav-login.active,
.side-nav .nav-logout.active {
  text-decoration: none;
  color: #fe8291;
}

.side-nav .sidenav-agent-pic {
  margin: 8px auto;
  padding: 0 1.5rem;
  text-align: center;
  align-content: center;
}

.side-nav .sidenav-agent-pic .sidenav-agent-photo {
  width: 80px;
}

.side-nav .sidenav-agent-pic .sidenav-agent-title,
.side-nav .sidenav-agent-pic .sidenav-agent-name {
  font-size: 14px;
  font-weight: 600;
}

.side-nav .sidenav-agent-pic .sidenav-agent-name {
  color: white;
}

.side-nav-sub-menu {
  display: none;
}

.side-nav-sub-menu li a {
  font-size: 14px !important;
}

#side-nav-underlay {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 64px;
  left: 0;
  background-color: transparent;
  z-index: 5;
}

@media screen and (min-width: 700px) {
  .side-nav .pc {
    display: none;
  }
}

/* ------------------- Login ------------------ */

.login-container {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 20;
  height: 100vh;
  width: calc(100% - 16px);
  position: fixed;
  top: 72px;
  left: 0;
  align-items: center;
}

.login-window {
  z-index: 20;
  background-color: black;
  position: relative;
  top: 60px;
  width: 400px;
  padding: 1rem;
  margin: 0 auto;
  border-radius: 15px;
  border: 1px solid gray;
}

.popup-title {
  display: flex;
  color: #fe8291;
}

.popup-title h1 {
  margin-top: 0;
  margin-right: auto;
}

.login-form-grid {
  display: grid;
  grid-template-columns: 35% 65%;
  padding-bottom: 8px;
}

.login-btn-grid-2 {
  width: 100%;
  margin-top: 16px;
  display: grid;
  grid-template-columns: 50% 50%;
  /* gap: 8px; */
}

@media screen and (max-width: 699px) {
  .login-window {
    width: calc(100% - 64px) !important;
    top: 120px;
  }
}

/* ------------------ Footer ------------------ */
.footer {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 32px auto 0 auto;
  /* position: relative; */
  z-index: 3;
  max-width: 1200px;
  height: 64px;
  /* bottom: 0; */
  border-top: 1px solid #fe8291;
}

.footer-container {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
  padding: 0;
  margin: auto;
  z-index: 3;
  width: 1200px;
  height: 64px;
}

.footer-link {
  text-decoration: none;
}

.footer-content {
  width: 100%;
  text-align: center;
  margin: auto auto 1rem;
  color: white;
  font-size: 2rem;
  background-color: black;
  padding: 4px;
}

.footer-content-small {
  background-color: #fe8291;
  color: white;
  font-size: 1.75rem;
}

.footer-content-smaller {
  color: white;
  font-weight: bold;
}
/*
@media screen and (max-width: 1099px) {
 
  .footer {
    height: 120px;
    padding: 0;
  }
  .footer-container {
    flex-direction: column;
    width: 100%;
  }

  .footer-content {
    font-size: 1.25rem;
    text-align: center !important;
    margin: auto auto 0.5rem !important;
  }

  .footer-content-small {
    font-size: 0.75rem !important;
  } 

}*/

/* --------------------- search ------------------------- */

.search-field {
  display: grid;
  grid-template-columns: 80px auto;
  margin: 8px;
  width: calc(100% - 16px);
  padding: 8px 0;
  border-top: 1px solid gray;
  height: auto;
}

.form-label {
  width: 80px;
  margin: 8px;
  display: flex !important;
  text-justify: inter-character;
}

.form-input-group {
  display: flex;
  flex-wrap: wrap;
}

.form-input {
  /* align-items: start; */
  margin: 8px 4px;
}

.form-input input[type='radio'],
.form-input input[type='checkbox'] {
  display: none;
}

.form-input-group select {
  padding: 2px 16px;
  margin: auto 4px;
}

.form-input label {
  padding: 4px 16px;
}

.form-input-label-special {
  background-color: black;
  color: white;
  border: 1px solid white;
  cursor: pointer;
}

.form-input-label {
  background-color: black !important;
  color: #fe8291 !important;
  border: 1px solid white;
  cursor: pointer;
}

.form-input-label-special.active {
  background-color: white;
  color: black;
}

.form-input-label.active {
  background-color: #fe8291 !important;
  color: white !important;
  border: 1px solid white;
}

.range-slider {
  width: 50%;
  cursor: pointer;
}

@media screen and (max-width: 699px) {
  .search-field {
    margin: 0 8px;
  }

  .form-label {
    font-size: 14px;
  }

  .form-input-label,
  .form-input-label-special {
    font-size: 14px;
  }
}

/* --------------------- dancer ------------------------- */
.grid-container-dancers {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 50% 50%;
}

@media screen and (max-width: 1099px) {
  .grid-container-dancers {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 699px) {
  .grid-container-dancers {
    margin: 16px 4px 16px;
  }
}

.dancer-item {
  position: relative;
  margin-bottom: 1rem;
}

.dancer-item-grid {
  width: calc(100%-16px);
  /* width: 100%; */
  margin: 8px;
  display: grid;
  grid-template-columns: 38% 62%;
  font-size: 1rem;
  /* border: 0.5px solid white; */
  /* max-height: 215px; */
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.dancer-item-image {
  aspect-ratio: 3 / 4;
  object-fit: contain;
  /* max-height: 210px; */
}

.dancer-item-image img {
  width: 100%;

  object-fit: scale-down;
}

.dancer-item-briefs {
  /* width: calc(100%-8px); */
  height: calc(100%-8px);
  margin: 0 4px;
  display: flex;
  flex-direction: column;
}

.dancer-item-briefs p {
  color: #fe8291;
  /* display: flex; */

  margin-top: 0;
  margin-bottom: 8px;
}

.dancer-item-first-line {
  font-weight: bold;
  font-size: 20px;
}

/* .dancer-item-first-line.first-item {
  padding-left: 8px;
  padding-right: 8px;
  background-color: white;
  width: fit-content;
} */

.dancer-item-first-line.first-item,
.dancer-item-first-line.second-item {
  padding: 0 4px;
  width: fit-content;
}

.no-mosaic-btn {
  /* font-weight: bold; */
  font-size: 1.5rem;
  position: absolute;
  left: 55px;
  top: 80px;
  padding: 2px 16px 4px;
}

.gray-text {
  color: gray !important;
}

.grid-four {
  display: grid;
  /* grid-template-columns: 30% 25% auto auto; */
  grid-template-columns: auto auto auto auto;
}

.grid-two {
  display: grid;
  grid-template-columns: 50% 50%;
}

.reserve-btns {
  display: flex;
  align-items: end;
  margin-top: auto;
  margin-bottom: 1rem;
  margin-left: auto;
}

.want-to-reserve {
  color: #fe8291;
  /* background-color: white; */
  /* border: 1px solid #fe8291; */
  display: flex;
  /* width: 80px; */
  margin: auto 0 0 auto !important;
  padding: 4px 16px;
  /* font-size: 12px; */
  font-weight: bold;
}

.btn-disabled {
  background-color: gray;
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 0;
  margin: 8px;
  border: none;
  border-radius: 5px;
}

.reserve-btn {
  background-color: #fe8291;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 4px 16px;
  margin: 8px 16px 4px 4px;
  border: 1px solid #fe8291;
  border-radius: 5px;
}

.dancer {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.dancer-firstImg {
  width: 100%;
  object-fit: contain;
  margin: 2rem auto;
  display: flex;
  border: 1px solid goldenrod;
}

.dancer-announcement {
  border: 1px solid #fe8291;
  color: white;
  min-height: 100px;
  display: flex;
}

.dancer-announcement p {
  padding: 1rem;
  margin: 0 auto;
  display: flex;
  text-align: center;
  font-size: large;
  font-weight: bold;
}

.dancer-comments-header {
  display: flex;
  margin: 2rem 0;
}

.dancer-comments-title {
  margin: 4px auto 4px 4px;
  font-size: 24px;
}

.dancer-video {
  max-width: 100%;
}

.dancer-photos {
  max-width: 100%;
  object-fit: contain;
}

.new-comment-box-container {
  position: fixed;
  top: 72px;
  left: 0;
  width: 100%;

  /* max-width: 1200px; */
  z-index: 10;
}
.new-comment-box-overlay {
  position: absolute;
  /* top: 72px; */
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: inherit;
}

.new-comment-box {
  position: relative;
  top: 25vh;
  margin: 0 auto;
}

.new-comment-box .info-box {
  width: 600px;
  /* z-index: 10; */
}

.new-comment-box textarea {
  width: calc(100% - 16px);
  height: 100px;
  overflow-y: scroll;
}

.new-comment-box .submit-btn {
  display: flex;
  margin: 8px auto;
}

@media screen and (max-width: 699px) {
  .dancer {
    padding: 12px;
  }
  .dancer-firstImg {
    height: auto;
    max-width: 100%;
  }

  .new-comment-box {
    width: 100%;
    margin: 0 auto;
    top: 10vh;
  }
  .new-comment-box .info-box {
    width: auto;
  }

  .new-comment-box-overlay {
    top: -10px;
  }

  .no-mosaic-btn {
    font-size: 18px;
    left: 30px;
    top: 50px;
    padding: 4px 8px;
  }
  .dancer-item-grid {
    /* width: calc(100vw - 16px); */
    font-size: 0.75rem;
    margin: 0;
  }

  .dancer-item-first-line {
    font-size: 12px;
  }
  .dancer-item-first-line.first-item {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .want-to-reserve {
    font-size: 12px;
  }
  .btn-gold,
  .reserve-btn {
    font-size: 12px;
    padding: 2px 4px;
    margin: 4px 0px 4px 8px;
  }
}

/* ----------------------Comments-------------------------- */
.comment-item {
  border-bottom: 1px solid rgb(42, 42, 42);
}
.comment-item-title-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr;
}

.control-panel-tabcontent {
  padding: 0 !important;
}

.comments-item-table-index,
.comment-item-table-index {
  padding: 4px !important;
}

.comments-item-dancerID,
.comment-item-dancerID {
  max-width: 120px;
}

.comments-item-accountType,
.comment-item-accountType,
.comments-item-overallRating,
.comment-item-overallRating {
  max-width: 64px;
}

.comments-item-table-index,
.comment-item-table-index,
.comments-item-approved,
.comment-item-approved,
.comments-item-score,
.comment-item-score {
  text-align: center !important;
}

.comment-item-score-select {
  padding: 4px;
}

/* ------------------------ Home -------------------------- */
.homepage-rules {
  margin: 8px;
}
.homepage-rules p {
  font-size: 12px;
  margin: 0;
}

@media screen and (max-width: 699px) {
}

/* --------------------- Management ---------------------- */
.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1;
  grid-template-rows: 1;
  -ms-grid-columns: 120px 360px;
  grid-template-columns: 120px 360px;
  padding-bottom: 5px;
  margin-right: auto;
}

.qr-preview {
  object-fit: contain;
  height: 200px;
  width: auto;
}

.video-upload-form {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1;
  grid-template-rows: 1;
  -ms-grid-columns: 250px 360px;
  grid-template-columns: 250px 360px;
  padding-bottom: 5px;
  margin-right: auto;
}

.submit-btn {
  margin-left: auto;
  margin-right: 0;
  padding: 8px 2rem;
}

/* --------------Control Panel Tabs-------------------- */
#contorl-panel-tabs .tablinks {
  font-size: 1.25rem !important;
}

.control-panel-tab-content,
.userTab-content {
  display: block;
}
.control-panel-table,
.users-table {
  margin: 1rem auto auto;
  border: 1px solid gray;
}

.control-panel-tablehead,
.users-tablehead {
  background-color: #333;
  border-bottom: 1px solid gray;
  height: 2rem;
}

.control-panel-table-title {
  margin: 1rem auto;
  text-align: center;
}

.control-panel-table th,
.control-panel-table td,
.users-table th,
.users-table td {
  padding: 4px 16px;
}

.control-panel-table-item,
.user-item {
  width: 100%;
  /* border: none; */
}
.user-item-table-index {
  text-align: center !important;
  padding: 4px 8px !important;
}

.control-panel-table-item th,
.control-panel-table-item td,
.user-item-username,
.user-item-email,
.user-item-weChat,
.user-item-accountType,
.user-item-vipUtil {
  text-align: left;
  margin-right: 1rem;
}

.user-item-balance {
  text-align: right;
}

.control-panel-table .comments-item-content {
  max-width: 400px;
}

.user-item-accountType-edit {
  padding: none;
  border: 1px solid #fe8291 !important;
}

.user-item-accountType-edit select,
.user-item-accountType-edit button {
  padding: 4px 8px !important;
}

.user-item-accountType-edit select {
  margin-right: 8px;
}

.control-panel-table-item button,
.user-item-actions button {
  padding-left: 8px;
  padding-right: 8px;
  margin: auto 4px;
}

.control-panel-table-item svg .user-item-actions svg {
  margin-right: 4px;
}

.add-btn {
  padding: 8px 20px 8px;
  font-size: 20px;
  margin: auto;
  display: flex;
}

.add-btn svg {
  margin-right: 1rem;
}

.admin-search-btn {
  padding: 4px 8px;
  font-size: 1.25rem;
  margin: 0 4px;
}

.dancerTab-header {
  display: flex;
  width: 100%;
}
.add-dancer-btn-div {
  margin-left: 0;
  margin-right: auto;
}

.add-dancer-btn-div h3 {
  margin: auto auto auto 2rem;
}

/* .comment-item {
  max-height: 120px;
} */

.comment-item-content {
  max-width: 450px;
  font-size: 16px;
  max-height: 100px;
  overflow-y: scroll;
}

/* AgentTab */
.table-form-input {
  padding: 8px 4px 4px;
  width: 4rem;
  /* margin-right: 1rem; */
  margin: auto 1rem auto;
}

.agent-tab-withdraw-btn {
  font-size: 1rem;
  padding: 4px 16px !important;
}

@media screen and (max-width: 699px) {
  /* .comment-item-content {
    width: 400px;
    height: 50px;
  } */
  .comment-item-content p {
    width: calc(100% - 12px);
  }
  .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .form-group label {
    margin-bottom: 4px;
  }
  .form-group input {
    width: auto;
    margin-bottom: 8px;
  }
  .form-group textarea {
    min-height: 100px;
  }
  .video-upload-form {
    display: flex;
    flex-direction: column;
  }
  .video-upload-form label {
    margin-bottom: 0.5rem;
  }
}

/* ---------------------- Waiting Spinner ------------------------- */
.waiting-spinner {
  z-index: 2;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  /* margin-top: 100px; */
  background-color: rgba(0, 0, 0, 0.726);
  display: flex;
  flex-direction: column;
}

.waiting-spinner-inner-wrapper {
  margin: auto;
}

.waiting-spinner img {
  /* position:relative; */
  z-index: 4;
}

.waiting-spinner p {
  z-index: 4;
  color: white;
  font-size: 1.5rem;
}

@media screen and (max-width: 699px) {
  .waiting-spinner p {
    font-size: 1rem;
  }
}

/* Pagination */
.pagination {
  display: inline-block;
}

.pagination-wrapper {
  margin-top: 2rem;
  text-align: center;
}

.pagination button {
  color: #fe8291;
  float: left;
  padding: 8px 12px;
  text-decoration: none;
  border: 1px solid #ddd;
  font-size: 1rem;
  font-weight: 400;
}

.pagination button.pagi-btn.show,
.pagination button.pagi-btn-show {
  display: inline;
}

.pagination button.pagi-btn-active {
  background-color: #fe8291;
  color: white;
  opacity: 0.8;
}

.pagination button:hover:not(.pagi-btn-active) {
  background-color: #ddd;
}

.pagi-btn {
  display: none;
}

.url-input {
  display: none;
}

#back-to-top {
  display: none;
  position: fixed;
  bottom: 160px;
  right: 30px;
  margin-left: auto;
  z-index: 10;
  font-size: 1rem;
  border: none;
  outline: none;
  background-color: white;
  color: #343a40;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #343a40;
  height: auto;
}

#back-to-top span {
  display: block;
}

#back-to-top:hover {
  background-color: #f4f4f4;
  color: #17a2b8;
}

/* -------------------------- BLOGS ---------------------------- */

.blogs-container {
  border-top: 1px solid gray;
  width: 70%;
  margin-left: calc((100vw - 1200px) / 2);
  margin-right: auto;
}

.blogs-nav {
  display: flex;
  padding-inline-start: 0;
}

.blogs-nav li:hover {
  color: #fe8291;
  cursor: pointer;
}

.blogs-nav .active {
  color: #fe8291;
}

.tablinks-small {
  list-style-type: none;
  font-size: 1rem !important;
  margin-right: 4rem;
}

.blogs-list {
  padding-inline-start: 0;
}

.blogs-list-item {
  list-style-type: none;
  display: grid;
  grid-template-columns: 20% 80%;
  border-bottom: 1px solid gray;
  padding: 16px 8px 8px;
  cursor: pointer;
}

.blogs-list-item:hover {
  background-color: #333;
}

.blogs-list-item-thumbnail {
  /* max-width: 100%; */
  object-fit: contain;
}

.blogs-list-item-brief {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
}

.blogs-list-item-brief h3 {
  margin-top: 0;
  margin-bottom: auto;
}

.blogs-list-item-footer {
  margin-top: auto;
  margin-bottom: 8px;
  display: flex;
  color: rgb(165, 165, 165);
}

.blogs-list-item-thumbnail img {
  max-width: 100%;
  object-fit: contain;
  aspect-ratio: 16 / 9;
}

.blog-title {
  width: 100%;
  text-align: center;
  margin-top: 3rem;
}

.blog-coverImg {
  display: flex;
  width: 60%;
  margin: 3rem auto auto;
}

.blog-title-footer {
  display: flex;
  margin-left: 10rem;
  margin-bottom: 2rem;
}

.blog-title-footer p {
  margin: 1rem 3rem;
}

.blog-content {
  padding: 2rem 4rem;
  border-top: 1px solid gray;
}

.blog-content img {
  display: flex;
  margin: 1rem auto;
  width: 100%;
}

.blog-top-badge {
  font-size: 10px;
  padding: 2px 4px;
  color: #fe8291;
  border: 1px solid #fe8291;
  border-radius: 2px;
  margin-left: 8px;
}

@media screen and (max-width: 699px) {
  .blogs-container {
    width: 100%;
    margin: 65px auto auto;
  }

  .tablinks-small {
    margin-right: 2rem;
  }

  .blogs-list-item {
    grid-template-columns: 40% 60%;
  }

  .blogs-list-item-brief h3 {
    font-size: 14px;
  }

  .blogs-list-item-footer {
    font-size: 12px;
  }

  .blog-title {
    width: 95%;
    text-align: center;
    margin: 1rem auto;
    font-size: 20px;
  }

  .blog-coverImg {
    display: flex;
    width: 95%;
    margin: 1rem auto auto;
  }

  .blog-title-footer {
    display: flex;
    width: 95%;
    margin: 8px auto;
  }

  .blog-title-footer p {
    margin: 8px;
    color: rgb(165, 165, 165);
  }

  .blog-content {
    /* display: flex; */
    width: 95%;
    /* padding: 1rem; */
    padding: 1rem 0;
    margin: auto;
    border-top: 1px solid gray;
  }
}

/* ---------------- blog form ------------------ */

.blog-form {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.blog-form-title {
  margin: 1rem;
  text-align: center;
  color: #fe8291;
  border-bottom: 1px solid gray;
  padding: 8px;
}

.blog-form .article-info {
  width: 100%;
  vertical-align: top;
}

.blog-form-item {
  display: flex;
  margin: 1rem;
  font-size: 20px;
}

.blog-form-item input,
.blog-form-item select {
  font-size: 1rem;
  padding: 8px 1rem;
}

.blog-form-item input[type='text'] {
  width: 80%;
}

/* .blog-form-item label, */
.blog-form-label {
  margin-right: 2rem;
}

.blog-form-label {
  min-width: 84px;
}

.blog-form-coverimg-note {
  margin: 1rem 0;
  color: #fe8291;
}

.article-coverimg-upload {
  cursor: pointer;
  width: 50%;
  border: 1.5px solid black;
}

.article-editor {
  /* background-color: #fff; */
  margin: 1rem;
}

.ql-toolbar {
  background-color: white;
}

.ql-editor {
  min-height: 400px;
}

.blog-submit {
  margin: 1rem auto;
  padding: 8px 2rem;
  font-size: 20px;
}

@media screen and (max-width: 699px) {
  .blog-form-item {
    display: block;
    text-align: left;
  }

  .blog-form-label {
    font-size: 1rem;
    margin: 8px auto 8px 0;
  }

  .blog-form-coverimg-note {
    font-size: 12px;
  }

  .blog-form .fullwidth-input {
    width: calc(100% - 32px);
  }
}

/* ----------------------------- 舞女签到 Attendance -------------------------- */
.attendance-container {
  padding: 8px;
  /* position: fixed; */
  margin-top: 200px;
}

.attendance-grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.attendance-item {
  /* max-width: calc(100% - 20px); */
  /* width: calc(100% - 2px); */
  padding: 2px;
  /* height: calc((20vw -20px) * 4 / 3); */
  position: relative;
  margin-bottom: 4px;
}

.attendance-item-img {
  position: relative;
}

.attendance-item-img:hover {
  cursor: pointer;
}

.attendance-item img {
  object-fit: contain;
  width: calc(25vw - 16px) !important;
  /* height: calc((25vw - 8px) / 3 * 4) !important; */
  background-color: #fff;
  z-index: 2;
}

/* .attendance-item-text {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
} */
.check-mark-overlay-container {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 5;
}
.check-mark-overlay {
  display: flex;
  font-size: 48px;
  color: rgb(23, 192, 23);
  padding: 4px;
  justify-content: center;
  margin: auto;
  /* background-color: #fff; */
}

.attendance-item .nickname,
.attendance-item .codeName {
  display: flex;
  margin: 0 auto;
  font-size: 12px;
  justify-content: space-around;
}

.attendance-search {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 8px 8px 0;
}

.attendance-search-container {
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.9);
}

.attendance-search-item {
  /* width: calc(50% - 16px); */
  margin: 8px;
}

.attendance-search-item-label {
  padding: 4px 16px 4px 4px;
  font-weight: bold;
  font-size: 18px;
  color: #fe8291;
}

.attendance-search-item select,
.attendance-search-item input[type='date'] {
  padding: 4px;
}

.attendance-search-item select {
  width: 80px;
}

.attendance-display-mode-switches {
  position: fixed;
  top: calc(50vh);
  right: 16px;
  display: flex;
  flex-direction: column;
}

.display-mode-switch-btn {
  margin: 4px 0;
  padding: 4px 8px;
}

@media screen and (min-width: 1200px) {
  .attendance-item img {
    width: 284px !important;
  }
}

/* 


.blog-form .author-info {
  display: inline-block;
  max-width: 560px;
  width: 50%;
  height: 100%;
  vertical-align: top;
}

.blog-form .author-info {
  margin-left: 1rem;
}

.blog-form .article-editor {
  display: block;
}

.blog-form-item {
  display: flex;
  margin: 8px;
  width: 100%;
}

.blog-form label {
  vertical-align: top;
  font-size: 20px;
}

.blog-form select,
.blog-form input {
  font-size: 1rem;
  padding: 4px 8px;
}

@media only screen and (max-width: 699px) {
  .blog-form {
    display: flex;
    flex-direction: column;
  }
  .blog-form .article-info,
  .blog-form .author-info {
    width: 100%;
  }

  .blog-form .author-info {
    margin-left: 0;
  }
}

#blog-category {
  width: 92px;
}

.blog-form textarea {
  min-height: 150px;
  margin-left: 1rem;
  padding: 8px;
  font-size: 1rem;
}
.blog-form .form-group-input {
  display: inline-block;
}
.blog-form .form-group-input-item {
  display: inline-block;
  margin-left: 1rem;
}

.blog-form .blog-form-label {
  display: inline-block;
  min-width: 48px;
  vertical-align: top;
}

.blog-coverimg {
  display: grid;
  grid-template-columns: 80px 120px auto;
  width: auto;
  margin: 1rem auto 2rem;
}

.blog-coverimg-submit {
  margin-left: 2rem;
}

#blog-form-coverimg-note {
  margin-left: 2rem;
  grid-column: 3;
}

.article-coverimg-upload {
  max-width: 120px;
}

.blog-upload-btn {
  font-size: 1rem;
  border-radius: 5px;
}
.second-item {
  margin-left: 1rem;
}

.fullwidth-input {
  width: calc(100% - 64px);
}

@media only screen and (max-width: 1099px) {
  .blog-form-flexable {
    display: block;
  }
  .second-item {
    margin-left: 0;
  }

  #blog-author {
    width: calc(100% - 64px);
  }
}

@media only screen and (max-width: 699px) {
  .blog-coverimg {
    grid-template-columns: 80px auto;
  }
  .blog-coverimg-submit {
    grid-column: 2;
    margin-left: 0;
    margin-top: 1rem;
  }
  #blog-form-coverimg-note {
    margin-left: 0;
    grid-column: 2;
  }
}

#editor .ql-editor {
  height: 300px !important;
  overflow-y: auto;
}

@media only screen and (min-width: 699px) {
  #editor .ql-editor {
    height: 500px !important;
  }
}

#editor .ql-editor img {
  width: auto !important;
  display: flex;
  margin: auto !important;
}

#article-body {
  margin-top: 2rem;
  padding: 0 !important;
}

.share-button-container {
  display: block;
  text-align: center;
  margin: 1rem auto 3rem auto;
}

.share-button-wrapper {
  cursor: pointer;
  display: inline-block;
  margin: 0 auto;
}

.share-button-wrapper i {
  font-size: 24px;
  color: gray;
  border: 2px solid gray;
  padding: 8px;
  border-radius: 32px;
}

.share-button-wrapper:hover {
  color: #17a2b8;
}
.share-button-wrapper i:hover {
  color: #17a2b8;
  border: 2px solid #17a2b8;
}

#manage-articles {
  overflow-x: scroll;
}
.blog-table {
  display: table;
}

.blog-table th {
  border-bottom: 1px solid black;
}
.blog-table td {
  padding: 4px 8px;
  vertical-align: top;
}

.blog-table-index {
  min-width: 18px;
  max-width: 24px;
}

.blog-table-title {
  min-width: 200px;
}

.blog-table-category {
  min-width: 86px;
}
.blog-table-author,
.blog-table-actions {
  min-width: 72px;
  text-align: center;
} */
